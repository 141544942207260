import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";

const SubmitAPPA = () => {
    const seo = {
        metaDesc: 'After submit a PPA - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="After submit a PPA - Inventiv.org" canonical='/provisionalbuilder' seo={seo} />

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <h1>1. After you submit a PPA</h1>
                    <div class="">
                        <p><strong>Table of Contents</strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1&nbsp; <a href="#uspto" class="d-clr">What the USPTO does?</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.2&nbsp; <a href="#you" class="d-clr">What you can do?</a></strong></p>
                        <p style={{ paddingLeft: 80 + 'px' }}><strong>1.2.1&nbsp; <a href="#patentpending" class="d-clr">May mark product with “patent pending” or “patent applied”</a></strong></p>
                        <p style={{ paddingLeft: 80 + 'px' }}><strong>1.2.2&nbsp; <a href="#ppas" class="d-clr">You can file Multiple PPAs</a></strong></p>
                        <p style={{ paddingLeft: 80 + 'px' }}><strong>1.2.3&nbsp; <a href="#convert" class="d-clr">Convert a PPA to a utility patent application</a>&nbsp;</strong></p>
                    </div>
                    <div class="">
                        <h2 id="uspto">1.1&nbsp; What the USPTO does?</h2>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>&nbsp;Once you file the application, USPTO sends you a filing receipt, and in case of any issue to correct, they send a notice to correct the deficiency, such as any missing filing fee.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <ol>
                            <li>The USPTO receives your application and accords a filing date for it.</li>
                            <li>It sends you a filing receipt.</li>
                            <li>If there are issues to correct, the USPTO will also send you a notice to correct the deficiency, such as fee payment or incomplete Cover Sheet. You have two months to reply. You need to reply fully by the deadline to avoid late fees. And if you don’t reply within six months, the application can become abandoned.</li>
                        </ol>
                    </div>
                    <div class="">
                        <h2 id="you">1.2&nbsp; What you can do?</h2>
                        <table class="table table-bordered mt-3"><tbody><tr><td width="623"><ul><li>Upon filing receipt, one can mark the product if on sale as ‘patent pending”, you only have one year under the PPA protection, so you should convert it to non-provisional application before the one year period lapses.</li></ul></td></tr></tbody>
                        </table>
                    </div>
                    <div class=""><h3 id="patentpending">1.2.1&nbsp; May mark product with “patent pending” or “patent applied”</h3><p>As soon as the USPTO mails your filing receipt, your application is considered pending. If a patented product is made or sold, you can display the “patent pending” or “patent applied” phrase. This is applicable whether you file a PPA, a utility patent application, design patent application, or plant patent application.<br /> While “patent pending” is more commonly used, it essentially means the same thing as “patent applied.” The phrases are used by manufactures to stake a claim on a product, indicating the USPTO is looking into a patent application that they have already filed.<br /> Though you can use these phrases, you don’t have any patent rights that you can enforce with them. Your invention can still be copied freely without your permission. People are deterred from copying your invention, however. Later on, if the USPTO grants your patent, then you can exercise your property rights, and these copiers have wasted a lot of time, energy, and money to copy you now illegally.<br /> If you use “patent applied” or “patent pending” falsely, meaning you haven’t actually filed a patent application, then you may be fined.</p></div>
                    <div class=""><h3 id="ppas">1.2.2&nbsp; You can file Multiple PPAs</h3><p>You may file multiple provisional patent applications as needed, but make sure you convert the provisional applications within one year of the earliest filing date.</p></div>
                    <div class=""><h3 id="convert">1.2.3&nbsp; Convert a PPA to a utility patent application</h3><p>A PPA automatically becomes abandoned when its pendency expires 12 months after the PPA’s filing date. You must file a non-PPA claiming benefit of the earlier PPA’s filing date before the PPA expires in order to preserve any benefits earned by filling a PPA.<br /> If your invention is “in use” or “on sale” in the United States, you file a PPA but fail to timely convert it into a utility patent application within the 12-month period, you may lose the right to ever patent the invention. This is because your inability to claim priority to the PPA leaves the use or sale of the invention as prior art against your own subsequent patent applications.</p></div>
                </div>
            </section>
        </Layout>
    )
};

export default SubmitAPPA;